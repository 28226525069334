import React from 'react';
import { useLocation } from 'react-router-dom'; // Para obtener el usuario de la URL
import { Box, Typography } from '@mui/material';

const Home = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const usuario = queryParams.get('usuario') || 'Usuario';

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Typography variant="h4" component="h1">
                Bienvenido {usuario}
            </Typography>
        </Box>
    );
};

export default Home;
