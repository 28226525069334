import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, Box, List, ListItem, ListItemButton, ListItemText, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Menu = ({ onLogout }) => {
    const navigate = useNavigate();
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const handleNavigation = (path) => {
        if (path === 'logout') {
            onLogout(); // Llama la función de logout
            navigate('/'); // Redirige al login
        } else {
            navigate(path); // Navega a la ruta correspondiente
        }
        setIsMobileOpen(false); // Cierra el menú en móviles
    };

    const toggleMobileDrawer = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const menuItems = [
        { text: 'Home', path: '/home' },
        { text: 'Agenda', path: '/agenda' },
        { text: 'Usuarios', path: '/usuarios' },
        { text: 'Turnos', path: '/turnos' },
        { text: 'Llamado', path: '/llamado' },
        { text: 'Recetas', path: '/recetas' },
        { text: 'Salir', path: 'logout' },
    ];

    const drawerContent = (
        <Box
            sx={{
                width: 250,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    padding: 2,
                    backgroundColor: '#1976d2',
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                Turnero Web
            </Typography>
            <List>
                {menuItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton onClick={() => handleNavigation(item.path)}>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            {/* Botón para abrir el menú en móviles */}
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleMobileDrawer}
                sx={{ display: { md: 'none' }, position: 'fixed', top: 10, left: 10 }}
            >
                <MenuIcon />
            </IconButton>

            {/* Menú lateral para pantallas grandes */}
            <Drawer
                anchor="left"
                open
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' },
                }}
            >
                {drawerContent}
            </Drawer>

            {/* Menú lateral para móviles */}
            <Drawer
                anchor="left"
                open={isMobileOpen}
                onClose={toggleMobileDrawer}
                variant="temporary"
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' },
                }}
            >
                {drawerContent}
            </Drawer>
        </>
    );
};

export default Menu;
