import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@mui/material';
import { startOfMonth, endOfMonth, eachDayOfInterval, format, parseISO, getDay } from 'date-fns';
import esLocale from 'date-fns/locale/es';

const Agenda = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [calendarData, setCalendarData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [turnos, setTurnos] = useState([]);

    const empresa = localStorage.getItem('empresa');

    // Fetch para obtener los datos del calendario
    const fetchCalendarData = async () => {
        const mes = currentDate.getMonth() + 1;
        const año = currentDate.getFullYear();

        try {
            const response = await fetch(`/api/agenda/calendario?mes=${mes}&año=${año}&empresa=${empresa}`);
            const data = await response.json();
            setCalendarData(data);
        } catch (error) {
            console.error('Error al cargar datos del calendario:', error);
        }
    };

    // Fetch para obtener los turnos de un día específico
    const fetchTurnos = async (fecha) => {
        try {
            const response = await fetch(`/api/agenda/turnos?fecha=${fecha}&empresa=${empresa}`);
            const data = await response.json();
            setTurnos(data);
        } catch (error) {
            console.error('Error al cargar turnos:', error);
        }
    };

    useEffect(() => {
        fetchCalendarData();
    }, [currentDate]);

    const handleDateClick = (fecha) => {
        setSelectedDate(fecha);
        fetchTurnos(fecha);
    };

    const handleCloseModal = () => {
        setSelectedDate(null);
        setTurnos([]);
    };

    const renderCalendar = () => {
        const start = startOfMonth(currentDate);
        const end = endOfMonth(currentDate);
        const daysInMonth = eachDayOfInterval({ start, end });

        // Determinar en qué día de la semana comienza el mes
        const startDayOfWeek = getDay(start); // 0 = Domingo, 1 = Lunes, etc.
        const totalDays = [...Array(startDayOfWeek).fill(null), ...daysInMonth]; // Agregar celdas vacías

        return (
            <Grid container spacing={0}>
                {totalDays.map((date, index) => {
                    if (date === null) {
                        // Celda vacía para los días previos al inicio del mes
                        return (
                            <Grid
                                key={`empty-${index}`}
                                item
                                xs={12 / 7}
                                sx={{
                                    border: '1px solid #ddd',
                                    padding: 2,
                                    textAlign: 'center',
                                    backgroundColor: '#f5f5f5',
                                }}
                            ></Grid>
                        );
                    }

                    const formattedDate = format(date, 'yyyy-MM-dd');
                    const turnos = calendarData.find((d) => format(parseISO(d.fecha), 'yyyy-MM-dd') === formattedDate)?.turnos || 0;

                    return (
                        <Grid
                            key={formattedDate}
                            item
                            xs={12 / 7} // 7 columnas
                            sx={{
                                border: '1px solid #ddd',
                                padding: 2,
                                textAlign: 'center',
                                backgroundColor: turnos > 0 ? '#03a9f4' : '#fff', // Azul para días con turnos
                                color: turnos > 0 ? '#fff' : '#000',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: turnos > 0 ? '#0288d1' : '#f5f5f5',
                                },
                            }}
                            onClick={() => handleDateClick(formattedDate)}
                        >
                            <Typography variant="body1">{format(date, 'd')}</Typography>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" textAlign="center" gutterBottom>
                {format(currentDate, 'MMMM yyyy', { locale: esLocale })}
            </Typography>
            <Paper elevation={3} sx={{ padding: 2, margin: 'auto', maxWidth: 800 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                    <Button onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))}>
                        Mes Anterior
                    </Button>
                    <Button onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))}>
                        Mes Siguiente
                    </Button>
                </Box>
                <Grid container spacing={0}>
                    {['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'].map((day) => (
                        <Grid
                            key={day}
                            item
                            xs={12 / 7}
                            sx={{
                                padding: 1,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                borderBottom: '2px solid #1976d2',
                            }}
                        >
                            {day}
                        </Grid>
                    ))}
                </Grid>
                {renderCalendar()}
            </Paper>
            <Dialog open={!!selectedDate} onClose={handleCloseModal}>
                <DialogTitle>Turnos del día {selectedDate}</DialogTitle>
                <DialogContent>
                    {turnos.length > 0 ? (
                        <Table>
                            <TableBody>
                                {turnos.map((turno, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{turno.hora}</TableCell>
                                        <TableCell>{`${turno.nombre} ${turno.apellido}`}</TableCell>
                                        <TableCell>{turno.profesional}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Typography>No hay turnos para este día.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Agenda;
