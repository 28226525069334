import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Menu from './modules/Menu';
import Login from './modules/Login';
import Home from './modules/Home';
import Usuarios from './modules/Usuarios';
import Agenda from './modules/Agenda'; // Importamos el módulo Agenda

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogin = () => {
        setIsAuthenticated(true); // Activa la autenticación después de login
    };

    const handleLogout = () => {
        setIsAuthenticated(false); // Desactiva la autenticación
    };

    return (
        <Router>
            {isAuthenticated && <Menu onLogout={handleLogout} />}
            <Box sx={{ marginLeft: isAuthenticated ? { md: '250px' } : 0, padding: 2 }}>
                <Routes>
                    <Route path="/" element={<Login onLogin={handleLogin} />} />
                    {isAuthenticated ? (
                        <>
                            <Route path="/home" element={<Home />} />
                            <Route path="/usuarios" element={<Usuarios />} />
                            <Route path="/agenda" element={<Agenda />} /> {/* Nueva ruta */}
                            <Route path="*" element={<Navigate to="/home" replace />} />
                        </>
                    ) : (
                        <Route path="*" element={<Navigate to="/" replace />} />
                    )}
                </Routes>
            </Box>
        </Router>
    );
};

export default App;
